@import url("https://fonts.googleapis.com/css2?family=Concert+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-prev:before,
.slick-next:before {
  color: black !important; /* Override the color to black */
}

.font-public-sans {
  font-family: "Public Sans", sans-serif;
}

.sweeping-line-button {
  overflow: hidden;
  position: relative;
}

.sweeping-line-button:after,
.sweeping-line-button:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.sweeping-line-button:after {
  animation: sweep 9s linear infinite;
  background: linear-gradient(270deg, #6474ff, transparent 100%);
  width: 50%;
}

@keyframes sweep {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}