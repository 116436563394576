@import url('https://fonts.googleapis.com/css2?family=Concert+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
.input {
    @apply block w-full mt-1 p-2 border border-gray-300 rounded-md;
  }
  
  .btn {
    @apply px-4 py-2 bg-blue-500 text-white rounded-md;
  }

  /* Custom styles for react-select */
.react-select__control {
    @apply border-gray-300 rounded-lg shadow-sm;
}

.react-select__menu {
    @apply rounded-lg shadow-lg;
}

.react-select__option--is-selected {
    @apply bg-indigo-600 text-white;
}

.react-select__option--is-focused {
    @apply bg-indigo-100;
}


body {
    margin: 0;
    padding: 0;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none !important;
    color: #2D2E2F;
}

.accordion {
    --bs-accordion-btn-color: #6E7072;
    --bs-accordion-color: #6E7072;
    --bs-accordion-btn-focus-border-color: ;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem #6e707249;
}

.table {
    font-weight: 500;
    font-size: 0.75rem;
    font-family: var(--font-text-md);
}

:root {

    --red: #E00707;
    --black: #2D2E2F;
    --blue: #027A97;
    --grey: #6E7072;
    --grey2: #323941;
    --bg-color-knowledge-hub: #ff8c00;
    --bg-color-regulatory-blog: #ffd000d4;
    --nav-bg: #EBEBEB;

    --font-sm: 14px;
    --font-md: 16px;
    --font-lg: 20px;
    --font-xl: 24px;

    --border-color: #9b9fa6;
    --nav-bg: #EBEBEB;
    /* --nav-btn-color: #0d0c22; */
    --nav-btn-color: red;
    --light-black: rgb(45, 46, 47);


    --font-sm: 1rem;
    --font-md: 1.5rem;
    --font-l: 2rem;
    --font-xl: 3rem;

    --font-w-sm: 100;
    --font-w-md: 300;
    --font-w-l: 500;
    --font-w-xl: 700;

    --nothing-found-width: 13.5rem;

    --global-font: 'Concert One', cursive;

    --text-font: 'Poppins', sans-serif;

    --font-text-sm: 'Poppins', sans-serif;
    --font-text-md: 'Open Sans', sans-serif;
    --font-text-l: 'Poppins', sans-serif;

}


.link {
    text-decoration: none;
}

p {
    margin: 0 !important;
}

.fade-in {
    opacity: 0;
    transition: opacity 250ms ease-in;
}

.fade-in.appear {
    opacity: 1;
}



/* // popins 700,24  | 500,16
// open sans 700,16,12 | 400,14 | 600,12,14
// pt mono 400,15 |  */


.my-btn {
    font-family: var(--font-text-l);
    font-weight: 500;
    /* letter-spacing: -0.2px; */
    text-align: center;
    border-radius: 4px;
    padding: 8px 15px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    white-space: nowrap;
}

.my-btn-disable {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.my-btn:hover {
    cursor: pointer;
    scale: 1.05;
    background-color: rgba(0, 0, 0, 0.1);
    color: white;
}

.my-btn-outline {
    border: 1px solid var(--border-color) !important;
    background-color: transparent;
}

.my-btn-primary {
    background-color: var(--nav-btn-color) !important;
    color: white;
    border: none !important;
}

.my-input__btn {
    height: 40px;
    font-family: 'PT Mono';
    font-size: 15px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    border: 1px solid --border-color;
    border-radius: 4px;
}